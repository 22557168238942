@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;300;400;700&display=swap");

$corefont: "Barlow";

.titleFont {
  font-family: "EarthOrbiter";
  text-transform: uppercase;
  font-size: 1.2em;
  color: $primary;
}
