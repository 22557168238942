/** @format */

.scenarioButton {
  border-radius: 31px;
  padding: 0;
  cursor: pointer;
}

.scenarioContent {
  background: $primaryAlpha;
  border-radius: 28px;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  padding: 20px;
  margin: 0;
  color: #fff;
}
.noScroll {
  overflow: hidden !important;
}
.scenarioMeta {
  color: #ffffffaa;
}

.screenBlock {
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.bg-transparent {
      background-color: transparent !important;
    }

    h5 {
      display: inline-block;
      max-width: 80%;
      margin: 0;
      &.d-flex {
        max-width: 100%;
      }
    }
  }
}

#discution-edition {
  #leftColumn {
    padding-right: 0px !important;
  }

  #close-item {
    top: 16px !important;
  }
}
#updateitem:hover {
  cursor: pointer;
}

#graph-zone-container {
  height: 90% !important;
}

#scenario-edition,
#discution-edition,
#review-screen {
  height: calc(100vh - 85px);
  overflow: hidden;

  #myTabContent {
    height: 100%;
    #graph {
      height: 100%;
      #graphContainer {
        height: 100%;
      }
    }
  }

  #discussionMetaButton {
    margin-bottom: 20px;
  }

  .meta-input {
    .dropdown-content {
      z-index: 100;
    }
    margin-bottom: 10px;
    .btn {
      svg {
        margin-top: 10px;
      }

      &disabled,
      &.btn-outline-secondary {
        display: none;
      }
      &.btn-warning {
        background-color: $secondary;
        color: white;
        border-radius: 0px;
        border: 0px;
        padding-top: 0px;
      }
    }

    .save-btn-container-discussion {
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      right: -40px;
      top: 4px;
      bottom: 13px;
      padding: 5px 0px;
    }
  }

  .nav-tabs {
    margin-bottom: 10px;
    .nav-item {
      text-transform: uppercase;
      a {
        color: $textMain;
        border-radius: 5px;
        border: 0;
        margin-right: 10px;

        svg {
          margin-right: 10px;
        }

        &.active {
          background-color: $primary;
          color: white;
        }
      }
    }

    .right-side-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
    }
  }

  #leftColumn {
    padding-right: 30px;
    //transition: all 0.7s;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    div {
      .srd-link-layer,
      .srd-node-layer {
        overflow-y: unset;
      }
    }

    &.visually-hidden {
      transform: translateX(calc(-100% + 40px));
    }

    .sideButton {
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 5px;
      position: absolute;
      right: -5px;
      top: 0px;
      bottom: 0px;
    }

    .fullHeightGraph {
      height: 100%;
      .diagram-column {
        height: 100%;
      }
    }
  }

  #rightColumn {
    display: flex;
    position: relative;
    transition: all 0.7s;
    overflow-x: hidden;
    height: 100%;

    &.visually-hidden {
      transform: translateX(calc(100% - 40px));
    }
    .sideButton {
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 5px;
      position: absolute;
      left: 15px;
      top: 0px;
      bottom: 0px;
    }

    #no-item-selected {
      margin-top: 36vh;
      letter-spacing: 2px;
      text-transform: uppercase;
      opacity: 0.5;
    }

    #editionContainer {
      height: 100%; //calc(100vh - 177px);
      overflow-y: hidden;

      &.scroll-y {
        overflow-y: auto;
      }
    }
    .card-header {
      min-height: 50px;
    }

    #close-item {
      position: absolute;
      right: 15px;
      top: 16px;
      font-size: 1.3em;
    }

    .message-edit {
      height: 80%;
      overflow-y: scroll !important;
    }

    #updateitem,
    .save-btn {
      margin-right: 20px;
      float: right;
      cursor: pointer;
      font-size: 1.3em;

      &.right-stick {
        right: 15px;
      }

      &.btn-secondary,
      &.btn-outline-secondary {
        background-color: rgba(0, 0, 0, 0);
        color: $textMain;
      }

      &.btn-outline-secondary {
        opacity: 0.3;
      }

      &.btn-danger {
        background-color: rgba(0, 0, 0, 0);
        opacity: 0.5;
        color: $danger;
        cursor: inherit;
      }

      &.btn-warning {
        background-color: rgba(0, 0, 0, 0);
        color: $secondary;
      }
    }
  }
}

.roommember {
  justify-content: flex-start;
  padding-top: 2px;
  align-items: center;
  font-size: 0.8em;
  font-weight: bold;

  .badge {
    margin-top: 3px;
  }
}

.card-header {
  padding-top: 10px;
  padding-bottom: 5px;
  h3 {
    font-size: 1.25em;
    letter-spacing: 2px;
  }
}

.partners-container {
  .add {
    margin: 0 !important;
    margin-top: -20px !important;
    position: relative !important;
  }
}

.npcs {
  .npc-list {
    height: calc(100vh - 250px);
    overflow-y: scroll;
  }
}

.click-layer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

#translations {
  .nav-tabs {
    margin-bottom: 10px;
    border: 0;

    .nav-item {
      text-transform: uppercase;
      a {
        color: $textMain;
        border-radius: 5px;
        border: 0;
        margin-right: 10px;

        svg {
          margin-right: 10px;
        }

        &.active {
          background-color: $primary;
          color: white;
        }
      }
    }
  }

  .preview-image {
    width: 100%;
    max-height: 25vh;
    height: 200px;
    object-fit: contain;
    object-position: center;
    position: relative;

    &.none {
      border: 0;
      opacity: 0.1;
    }
  }

  .delete-inline {
    border: 1px solid $red !important;
    background-color: rgba(0, 0, 0, 0) !important;
    color: $red !important;
    opacity: 0.5;
    &:hover {
      box-shadow: none !important;
      opacity: 1;
      color: white !important;
      background-color: $red !important;
    }
  }

  .delete {
    border: 1px solid $red !important;
    background-color: rgba(0, 0, 0, 0) !important;
    color: $red !important;
    opacity: 0.5;
    width: 100%;
    margin-top: -20px;
    &:hover {
      box-shadow: none !important;
      opacity: 1;
    }
  }
}
.empty-container {
  align-items: center;
  width: 100%;
  .empty-text-title {
    text-align: center;
    font-size: 2em;
  }
  .empty-content {
    text-align: center;
    align-self: center;
    margin-top: 20px;
  }
  .empty-button {
    text-transform: uppercase;
  }
}
