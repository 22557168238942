.tutorialButtons {
  width: 200px;
  height: 100%;
  width: 200px;
  text-align: left;
  overflow-y: auto;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  -webkit-box-shadow: 9px 0px 25px -4px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 9px 0px 25px -4px rgba(0, 0, 0, 0.04);
  box-shadow: 9px 0px 25px -4px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  color: rgb(0, 27, 72);
  background: #eeeff1;
  padding: 0px;
  margin: 0px;
}

.tutorialContent {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 5px;
}

.tutorialButton {
  width: 100%;
  background: #00000000;
}
.tutorialButton :focus {
  background: #B031D6;
}
.tutorialButton :active {
  background: #B031D6;
}
.tutorialButton :focus {
  background: #B031D6;
}

.centeredImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
